<template>
  <div class="web-block">
    <div class="block-line-div" v-for="(item,index) in comItems" :key="index">
      <a-row class="block-line" :gutter="[item.blockStyle.gutter]" :style="{ backgroundColor: item.blockStyle.backgroundColor,margin:item.blockStyle.margin + 'px',paddingTop:item.blockStyle.paddingTop  + 'px',paddingBottom:item.blockStyle.paddingBottom  + 'px' }">
        <a-col v-for="(block,col) in item.layNumber" :key="col" :span="parseInt(24 / item.layNumber)">
          <div class="block-box">
            <!-- {{item.blockName}} -->
            <div class="block-bar">
              <div class="bar-title">块</div>
              <div class="bar-item" @click="editBlock(index,col)">设置</div>
              <div class="bar-item" @click="deleteBlock(index,col)">清空</div>
            </div>
            <!----模块开始---->
            <!--一张图-->
            <ImageGraph v-if="item.blockData[col].subName == 'image'" :data="item.blockData[col].data" :style="item.blockData[col].style" />
            <!--轮播广告-->
            <Advs v-else-if="item.blockData[col].subName == 'advs'" :data="item.blockData[col].data" :style="item.blockData[col].style" />
            <RichEditor v-else-if="item.blockData[col].subName == 'rich'" :data="item.blockData[col].data" :style="item.blockData[col].style" />
            <Article v-else-if="item.blockData[col].subName == 'article'" :data="item.blockData[col].data" :style="item.blockData[col].style" />
            <TabList v-else-if="item.blockData[col].subName == 'tab'" :data="item.blockData[col].data" :style="item.blockData[col].style" />
            <Idefined v-else-if="item.blockData[col].subName == 'idefined'" :data="item.blockData[col].data" :style="item.blockData[col].style" />
            <Iempty v-else />
            <!----模块结束---->
          </div>
        </a-col>
      </a-row>
      <div class="line-bar">
        <div class="bar-title">布局</div>
        <div class="bar-item" @click="editLayout(item,index)">设置</div>
        <div class="bar-item">编辑</div>
        <div class="bar-item">
          <a-tooltip>
            <template #title>复制</template>
            <span class="bar-item-text">
              <CopyOutlined />
            </span>
          </a-tooltip>
        </div>
        <div class="bar-item">
          <a-tooltip>
            <template #title>上移</template>
            <span class="bar-item-text">
              <ArrowUpOutlined />
            </span>
          </a-tooltip>
        </div>
        <div class="bar-item">
          <a-tooltip>
            <template #title>下移</template>
            <span class="bar-item-text">
              <ArrowDownOutlined />
            </span>
          </a-tooltip>
        </div>
      </div>
    </div>
  </div>
  <a-modal ref="modalRef" class="layout-layer" :width="360" :mask="false" :maskClosable="false" v-model:visible="visible" :wrap-style="{ overflow: 'hidden' }" @ok="handleOk">
    <div class="layout-editor">
      <a-form :label-col="{ span: 6 }" :wrapper-col="{ span: 18 }" autocomplete="off">
        <a-form-item label="模块名称">
          <a-input v-model:value="comItems[comActive].blockName" placeholder="请输入模块名称" />
        </a-form-item>
        <a-form-item label="上外边距">
          <a-slider v-model:value="comItems[comActive].blockStyle.paddingTop" :min="0" :max="200" />
        </a-form-item>
        <a-form-item label="下外边距">
          <a-slider v-model:value="comItems[comActive].blockStyle.paddingBottom" :min="0" :max="200" />
        </a-form-item>
        <a-form-item label="栅格间距">
          <a-slider v-model:value="comItems[comActive].blockStyle.gutter" :min="0" :max="200" />
        </a-form-item>
        <a-form-item label="内边距">
          <a-slider v-model:value="comItems[comActive].blockStyle.margin" :min="0" :max="200" />
        </a-form-item>
        <a-form-item label="背景颜色">
          <ColorPicker picker-type="chrome" format="hex" v-model:pureColor="
                        comItems[comActive].blockStyle.backgroundColor
                      " />
          <span class="color-select-code">Hex：{{comItems[comActive].blockStyle.backgroundColor}}</span>
        </a-form-item>
      </a-form>
    </div>
    <template #title>
      <div ref="modalTitleRef" class="layout-editor-drag">
        <MenuOutlined />
        <span class="layout-editor-title">模块布局</span>
      </div>
    </template>
    <template #footer>
      <div class="layout-editor-footer">
        <div class="layout-editor-footer-btn layout-editor-footer-ok" @click="okLayout">
          确定
        </div>
        <div class="layout-editor-footer-btn layout-editor-footer-cancel" @click="cancelLayout">
          取消
        </div>
      </div>
    </template>
    <template #modalRender="{ originVNode }">
      <div :style="transformStyle">
        <component :is="originVNode" />
      </div>
    </template>
  </a-modal>

  <a-drawer :width="360" title="模块设置" placement="left" class="drawer-container" :maskClosable="false" :visible="visibleBlock" @close="onCloseBlock">
    <template #extra>
      <a-button style="margin-right: 8px" @click="onCloseBlock">取消</a-button>
      <a-button type="primary" @click="onCloseBlock">确定</a-button>
    </template>
    <div class="block-style-data">
      <a-card title="数据" :bordered="false">
        <a-form :label-col="{ span: 6 }" :wrapper-col="{ span: 18 }" autocomplete="off">
          <a-form-item label="模块结构">
            <a-select v-model:value="comItems[comActive].blockData[blockActive].subName" @change="changeDataType">
              <a-select-option :value="item.value" v-for="(item,index) in dataType" :key="index">{{item.title}}</a-select-option>
            </a-select>
          </a-form-item>
          <a-form-item label="标题">
            <a-input v-model:value="comItems[comActive].blockData[blockActive].data.title" placeholder="请输入标题" />
          </a-form-item>
          <a-form-item label="选择数据">
            <a-select v-model:value="comItems[comActive].blockData[blockActive].data.formID" :options="optionsBlock" :field-names="{ label: 'name', value: 'id', options: 'children' }">
            </a-select>
          </a-form-item>
        </a-form>
      </a-card>
      <a-card title="样式" :bordered="false">
        <a-form :label-col="{ span: 6 }" :wrapper-col="{ span: 18 }" autocomplete="off">
          <a-form-item label="模块名称">
            <a-input v-model:value="comItems[comActive].blockName" placeholder="请输入模块名称" />
          </a-form-item>
          <a-form-item label="外边距">
            <a-slider v-model:value="comItems[comActive].blockStyle.margin" :min="0" :max="100" />
          </a-form-item>
          <a-form-item label="内边距">
            <a-slider v-model:value="comItems[comActive].blockStyle.padding" :min="0" :max="100" />
          </a-form-item>
        </a-form>
      </a-card>
    </div>
  </a-drawer>

</template>
<script>
import {
  defineComponent,
  // reactive,
  ref,
  computed,
  onMounted,
  watch,
  watchEffect,
} from "vue";
import {
  CopyOutlined,
  ArrowUpOutlined,
  ArrowDownOutlined,
  MenuOutlined,
} from "@ant-design/icons-vue";
import { ColorPicker } from "vue3-colorpicker";
import { useDraggable } from "@vueuse/core";
import * as Api from "./api";
import {
  ImageGraph,
  Advs,
  RichEditor,
  Article,
  TabList,
  Idefined,
  Iempty,
} from "./block";
import "./style/block.less";
export default defineComponent({
  components: {
    ColorPicker,
    CopyOutlined,
    ArrowUpOutlined,
    ArrowDownOutlined,
    MenuOutlined,
    ImageGraph,
    Advs,
    RichEditor,
    Article,
    TabList,
    Idefined,
    Iempty,
  },
  props: {
    dataBlock: Object,
    activeBlock: Number,
  },
  setup(props, { emit }) {
    console.log(props.dataBlock);
    console.log(props.activeBlock);

    // console.log(props.dataBlock);
    // console.log(props.activeBlock);

    const comItems = computed({
      get: () => props.dataBlock,
      set: (value) => emit("update:dataBlock", value),
    });

    // 当前活跃的布局模块
    const comActive = ref(props.activeBlock);

    const editLayout = (item, index) => {
      comActive.value = index;
      visible.value = true;
    };

    const visible = ref(false);
    const modalTitleRef = ref(null);

    const showModal = () => {
      visible.value = true;
    };

    const { x, y, isDragging } = useDraggable(modalTitleRef);

    const handleOk = (e) => {
      console.log(e);
      visible.value = false;
    };

    const startX = ref(0);
    const startY = ref(0);
    const startedDrag = ref(false);
    const transformX = ref(0);
    const transformY = ref(0);
    const preTransformX = ref(0);
    const preTransformY = ref(0);
    const dragRect = ref({
      left: 0,
      right: 0,
      top: 0,
      bottom: 0,
    });
    watch([x, y], () => {
      if (!startedDrag.value) {
        startX.value = x.value;
        startY.value = y.value;
        const bodyRect = document.body.getBoundingClientRect();
        const titleRect = modalTitleRef.value.getBoundingClientRect();
        dragRect.value.right = bodyRect.width - titleRect.width;
        dragRect.value.bottom = bodyRect.height - titleRect.height;
        preTransformX.value = transformX.value;
        preTransformY.value = transformY.value;
      }

      startedDrag.value = true;
    });
    watch(isDragging, () => {
      if (!isDragging) {
        startedDrag.value = false;
      }
    });
    watchEffect(() => {
      if (startedDrag.value) {
        transformX.value =
          preTransformX.value +
          Math.min(
            Math.max(dragRect.value.left, x.value),
            dragRect.value.right
          ) -
          startX.value;
        transformY.value =
          preTransformY.value +
          Math.min(
            Math.max(dragRect.value.top, y.value),
            dragRect.value.bottom
          ) -
          startY.value;
      }
    });
    const transformStyle = computed(() => {
      return {
        transform: `translate(${transformX.value}px, ${transformY.value}px)`,
      };
    });

    const okLayout = () => {
      visible.value = false;
    };

    const cancelLayout = () => {
      visible.value = false;
    };

    // 模块内容类型
    // const dataType = ["advs", "image", "rich", "article", "tab"];
    const dataType = [
      { title: "文档列表", value: "article" },
      { title: "文档详情", value: "article_detail" },
      { title: "幻灯片", value: "advs" },
      { title: "图片", value: "image" },
      { title: "富文本", value: "rich" },
      { title: "TAB标签页", value: "tab" },
      { title: "地图", value: "map" },
      { title: "自定义", value: "idefined" },
    ];

    const optionsBlock = ref([]);

    //模块编辑
    const blockActive = ref(0);

    const visibleBlock = ref(false);
    const editBlock = (index, col) => {
      // 布局模块索引
      comActive.value = index;
      // 模块索引
      blockActive.value = col;

      // 打开抽屉窗口
      visibleBlock.value = true;
      // comItems.value[index].blockData[col] = {
      //   subName: "article",
      //   subTitle: "富文本",
      //   data: {
      //     category_id: 2,
      //   },
      // };
    };

    // 置空模块
    const deleteBlock = (index, col) => {
      comItems.value[index].blockData[col] = {
        subName: "",
        subTitle: "",
        data: {},
      };
    };

    //切换数据结构
    const changeDataType = (value) => {
      console.log(value);
      // comItems.value[comActive.value].blockData[blockActive.value] = {
      //   subName: "article",
      //   subTitle: "富文本",
      //   data: {
      //     title: "文档1标题",
      //     formID: 0,
      //     fromData: null,
      //   },
      // };
      // 根据选择的数据类型请求数据
      Api.get_block_data({
        type: value,
      }).then((res) => {
        console.log(res);
        optionsBlock.value = res.data;
      });
    };

    const onCloseBlock = () => {
      visibleBlock.value = false;
    };

    onMounted(() => {});

    return {
      comItems,
      comActive,
      editLayout,
      visible,
      showModal,
      handleOk,
      modalTitleRef,
      transformStyle,
      okLayout,
      cancelLayout,
      editBlock,
      deleteBlock,
      dataType,
      optionsBlock,
      visibleBlock,
      blockActive,
      onCloseBlock,
      changeDataType,
    };
  },
});
</script>