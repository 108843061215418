<template>
  <div class="article-list">
    <div class="article-nav">
      {{data.title == ''?'标题':data.title}}
      <a href="#" class="more">更多</a>
    </div>
    <div class="list-items-box">
      <div class="list-item" v-for="(item) in 6" :key="item">山一程，水一程，身向榆关那畔行，夜深千帐灯。风一更，雪一更，聒碎乡心梦不成，故园无此声。</div>
    </div>
  </div>
</template>
<script>
import { defineComponent } from "vue";
export default defineComponent({
  props: {
    data: Object,
    style: Object,
  },
  setup() {
    return {};
  },
});
</script>
<style lang="less" scoped>
.article-list {
  min-height: 200px;
  border: 1px solid #f0f0f0;
  .article-nav {
    font-size: 18px;
    padding: 10px 20px;
    border-bottom: 1px solid #f0f0f0;
    .more {
      font-size: 14px;
      display: block;
      float: right;
      color: #333333;
    }
  }
  .list-items-box {
    padding: 10px;
    .list-item {
      width: 100%;
      margin: 6px 0;
      display: inline-block;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}
</style>